define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/routers/aclRouter',

  'modules/upx/acls/loggedIn',
  'modules/shop.cash-register-retail/acls/settings',
  'modules/shop.cash-register-retail/controllers/posSettings',
], (
  $, _, Backbone, Router,
  LoggedInAcl, SettingsAcl, Controller,
) => Router.extend({

  controller: new Controller(),

  appRoutes: {
    'pos-settings': 'settings',
    'pos-settings/reload': 'reload',
  },

  acls: [
    new LoggedInAcl(),
    new SettingsAcl(),
  ],

}));
