define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop.cash-register-retail/acls/commerceAuth',
  'modules/shop.cash-register-retail/acls/notAcl',
  'modules/shop.cash-register-retail/controllers/dailyReports',
], (
  $, _, Backbone, Router, LoggedInAcl, CommerceAuthAcl, NotAcl, Controller,
) => Router.extend({

  controller: new Controller(),

  appRoutes: {
    'daily-reports(/:swapTo)(/:swapToChild)': 'dailyReports',
  },

  acls: [
    new LoggedInAcl(),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderOnlyAlias })),
  ],

}));
