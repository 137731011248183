define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop.cash-register-retail/controllers/hospitality',
], (
  $, _, Backbone, Router, LoggedInAcl, Controller,
) => Router.extend({

  controller: new Controller(),

  appRoutes: {
    hospitality: 'overview',
  },

  acls: [
    new LoggedInAcl(),
  ],

}));
