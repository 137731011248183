define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop.cash-register-retail/controllers/customers',
], (
  $, _, Backbone, Router, LoggedInAcl, Controller,
) => Router.extend({

  controller: new Controller(),

  appRoutes: {
    'customers/search(/:swapTo)(/:id)': 'search',
    'customers/create': 'create',
    'customers/create-company': 'createCompany',
    'customers/details/:id(/:swapTo)': 'details',
  },

  acls: [
    new LoggedInAcl(),
  ],

}));
