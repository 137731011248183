var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "\n<div data-region=\"popup\" class=\"hidden\"></div>\n\n<button data-action=\"top-up\" class=\"btn btn-primary designer-box rounded\">\n    <span>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"top_up",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":5,"column":39}}}))
    + "</span>\n</button>\n<button data-action=\"paying-card\" class=\"btn btn-success designer-box rounded\">\n    <span>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"payment_card",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":8,"column":45}}}))
    + "</span>\n</button>\n<button data-action=\"member-card\" class=\"btn btn-success designer-box rounded\">\n    <span>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"member_card",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":10},"end":{"line":11,"column":44}}}))
    + "</span>\n</button>";
},"useData":true});