define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/routers/aclRouter',
  'modules/shop.cash-register-retail/controllers/customerScreens',
], (
  $, _, Backbone, Router, Controller,
) => Router.extend({

  controller: new Controller(),

  appRoutes: {
    'customer-screen': 'customerScreen',
  },

  acls: [

  ],

}));
